<template>
    <div>
        <div class="row">

            <div class="col-12" v-if="vehicle">
                <div class="row">
                    <div v-if="!loading" class="col-12 text-center">
                        <div class="number-plate number-plate-gbr">{{$route.params.registration}}</div>
                    </div>
                    <div v-if="!loading" class="col-12 mg-t-10">
                        <div v-if="vehicle.blacklist.national.by.length > 0" class="card card-body tx-white-8 bg-danger bd-0 text-center">
                            <p style="font-size: 20px; margin-bottom: 0px;"><strong>BLACKLISTED BY</strong></p>
                            <p style="font-size: 20px; margin-bottom: 0px;" v-for="entry in vehicle.blacklist.national.by">{{entry.name}} for <span class="tx-medium">{{entry.reason || "Unknown"}}</span></p>
                        </div>

                        <div class="card card-body tx-white-8 bg-primary bd-0 text-center" v-bind:class="{'mg-t-20': vehicle.blacklist.national.by.length}">
                            <p style="font-size: 20px; margin-bottom: 0px;"><strong>CLAIM OVERVIEW</strong></p>
                            <p style="font-size: 20px; margin-bottom: 0px;">{{vehicle.blacklist.local.claims}} Claims Oustanding</p>
                            <p style="font-size: 20px; margin-bottom: 0px;">£{{vehicle.blacklist.local.value || 0}} Owed</p>
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
        <div class="row mg-t-20">
            <div class="col-6">
                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Watchlist
                    </div>
                    <div class="card-body">

                        <button v-if="blacklistedLocally" @click="unwatchlistVehicle" class="btn btn-sm btn-success">Remove from Blacklist</button>
                        <button v-else class="btn btn-sm btn-danger" @click="watchlistVehicle">Add to Blacklist</button>

                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Notes
                    </div>
                    <div class="card-body" v-show="!loading">

                        <div class="br-chat-body" style="max-height: 500px; width: 100%; overflow-y: scroll;">
                            <p v-if="notes.length < 1">No notes for this vehicle!</p>
                            <div v-for="n in notes" v-bind:class="{'flex-row-reverse': n.user.id === this.$store.state.auth.user.id}" class="media">
                                <div class="media-body">
                                    <div class="msg-wrapper">
                                        {{n.note}}
                                    </div><!-- msg-wrapper -->
                                    <div><unix-time :time="n.created_at"/>&nbsp;by&nbsp;<span>{{n.user.email}}</span></div>
                                </div><!-- media-body -->
                            </div>
                        </div>

                        <br/>
                        <br/>
                        <br/>

                        <div class="br-chat-footer mg-t-20">
                            <div><input v-model="newNote" type="text" class="form-control" placeholder="Write your message here..." @keydown.enter="createNote"></div>
                            <nav class="nav">
                                <a v-if="!sendingMessage" href="javascript:void(0);" @click="createNote" class="nav-link"><i class="icon ion-android-send"></i></a>
                                <div v-else class="sk-wave">
                                    <div class="sk-rect sk-rect1 bg-white"></div>
                                    <div class="sk-rect sk-rect2 bg-white"></div>
                                    <div class="sk-rect sk-rect3 bg-white"></div>
                                    <div class="sk-rect sk-rect4 bg-white"></div>
                                    <div class="sk-rect sk-rect5 bg-white"></div>
                                </div>
                            </nav>
                        </div>

                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Customer Service Message
                    </div>
                    <div class="card-body" v-show="!loading">

                        <p>If you would like the display to notify staff of something when this vehicle comes onto site
                            but do not wish to blacklist it, place a message below.</p>

                        <p class="tx-danger">If the vehicle is <b>blacklisted</b> this message will not show on the display. Please use the notes if you wish for it to display on a blacklist alert!</p>

                        <textarea v-model="csm" class="form-control form-control-dark" placeholder="Write your message here..."></textarea>
                        <br/>
                        <submit-button :background="'btn-sm btn-success'" :clicked="updatingCustomerService" :text="'Save'" @click="updateCustomerServiceMessage"></submit-button>
                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
    name: 'Vehicle',
    components: {
        SubmitButton
    },
    data(){
        return {
            vehicle: null,
            csm: null,
            notes: []
        }
    },
    mounted(){
        this.getVehicle();
    },
    methods: {
        getVehicle(){
            axios.get(`https://api.varsanpr.com/api/vehicle/${this.$route.params.registration}?client_id=${this.$store.state.auth.user.selectedClient}`, { headers: authHeader() })
            .then(response => {
                this.vehicle = response.data;
                this.getCustomerServiceMessage();
                this.getNotes();
            })
            .catch(error => {
                console.log(error);
            });
        },
        getCustomerServiceMessage(){
            axios.get(`https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/message?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
            .then(response => {
                this.csm = response.data.message || "";
            })
            .catch(error => {
                this.$error("Unable to load customer service message", error);
            });
        },
        getNotes(){
            axios.get(`https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/notes?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
            .then(response => {
                this.notes = response.data.notes;
            })
            .catch(error => {
                this.$error("Unable to load notes", error);
            });
        },
        updateCustomerServiceMessage(){
            axios.put(`https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/csm?client_id=${this.$store.state.auth.user.selectedClient}`, {
                message: this.csm
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Customer service message updated");
            })
            .catch(error => {
                this.$error("Unable to update customer service message", error);
            });
        },
        createNote(){
            this.sendingMessage = true;
            if(this.newNote.length < 1){
                this.$error("Please provide a valid note");
                this.sendingMessage = false;
                return;
            }
            axios.put(`https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/notes?client_id=${this.$store.state.auth.user.selectedClient}`, {
                note: this.newNote
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Note added");
                this.newNote = "";
                this.getNotes();
                this.sendingMessage = false;
            })
            .catch(error => {
                this.$error("Unable to add note", error);
                this.sendingMessage = false;
            });
        },
        async watchlistVehicle(e){
            const {value: reason} = await Swal.fire({
                title: `Select a reason for blacklisting ${this.vehicle.registration}`,
                input: 'select',
                inputOptions: {
                    "Drive Off": "Drive Off",
                    "No Means of Payment": "No Means of Payment",
                    "Fly Tipping": "Fly Tipping",
                    "Theft": "Theft",
                    "Aggressive": "Aggressive",
                    "Plate Mismatch": "Plate Mismatch"
                },
                inputPlaceholder: 'Select a reason',
                showCancelButton: true
            });

            if(reason){
                axios.put(`https://api.varsanpr.com/api/blacklist`, {
                    registration: this.vehicle.registration,
                    reason: reason,
                    client_id: this.$store.state.auth.user.selectedClient
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    this.$success("Vehicle blacklisted");
                    this.getVehicle();
                })
                .catch(error => {
                    this.$error("Unable to blacklist vehicle", error);
                });
            }
        },
        unwatchlistVehicle(){
            axios.delete(`https://api.varsanpr.com/api/blacklist`, {
                headers: authHeader(),
                data: {
                    registration: this.vehicle.registration,
                    client_id: this.$store.state.auth.user.selectedClient
                }
            })
            .then(response => {
                this.$success("Vehicle removed from blacklist");
                this.getVehicle();
            })
            .catch(error => {
                this.$error("Unable to remove vehicle from blacklist", error);
            });
        }
    },
    computed: {
        blacklistedLocally: function() {
            if(!this.vehicle || !this.vehicle.blacklist.national.by) return false;
            return this.vehicle.blacklist.national.by.filter((n) => {
                return parseInt(n.client_id) == parseInt(this.$store.state.auth.user.selectedClient);
            }).length > 0;
        }
    }
}
</script>

<style scoped>
.number-plate
{
    background-color: #f8ef1f;
    border-radius: 0.25rem;
    color: black;
    display: inline-block;
    font-family: "Arial Black";
    font-weight: bold;
    padding: 0.1rem 0.9rem;
    font-size: 1.5rem;
}

.number-plate-gbr
{
    background-image: url(/img/uk-plate-logo.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    padding-left: 2rem;
}
</style>